<template>
  <div class="app-container">
    <CrudTable ref="table" entity="HermesClientRate" :columns="columns" />
  </div>
</template>

<script>
import crudIndexMixin from '@/mixins/crud-index';
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  mixins: [crudIndexMixin],
  data() {
    return {
      columns: [
        { field: 'clientDisplay', label: 'hermes.client' },
        { field: 'productDisplay', label: 'hermes.product' },
        { field: 'zoneDisplay', label: 'hermes.zone' },
        { field: 'combi', label: 'hermes.combi' },
        { field: 'rate', label: 'hermes.rate' }
      ]
    };
  }
};
</script>

<style scoped></style>
